<template>
  <b-link class="brand-logo">
    <b-link
      class="navbar-brand"
      to="/"
    >
      <span
        v-if="showLogo"
        class="brand-logo"
      >
        <b-img
          :src="appLogoImage"
          alt="logo"
        />
      </span>
      <h2 class="brand-text">
        {{ appName }}
      </h2>
    </b-link>
  </b-link>
</template>
<script>
import { $themeConfig } from '@themeConfig'
import { BImg, BLink } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BLink,
  },
  setup() {
    const orgContact = $themeConfig.org.contact
    const { appName, appLogoImage } = $themeConfig.app
    const showLogo = false
    return {
      orgContact,
      appName,
      appLogoImage,
      showLogo,
    }
  },
}
</script>
